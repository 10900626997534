<template>
    <v-card flat style="border-radius: 10px" outlined class="ma-0 pa-0">
        <v-col cols="12" class="font-weight-bold mx-0 mt-2">
            <v-row class="d-flex flex-wrap px-2 align-center">
                <v-chip
                    class="text-capitalize mr-2 white--text"
                    dark
                    :color="
                        cost.preDesign &&
                        cost.preDesign[cost.preDesign.length - 1]?.rejectedOn
                            ? 'red'
                            : cost.engineering && cost.engineer == '123'
                            ? 'green'
                            : cost.engineering == false &&
                              cost.engineeringDate &&
                              cost.engineer
                            ? 'primary'
                            : cost.engineering &&
                              !['123', undefined].includes(cost.engineer)
                            ? '#ff7800'
                            : ''
                    "
                    >{{
                        cost.preDesign &&
                        cost.preDesign[cost.preDesign.length - 1]?.rejectedOn
                            ? 'Rejected'
                            : cost.engineering && cost.engineer == '123'
                            ? 'Pending'
                            : cost.engineering == false &&
                              cost.engineeringDate &&
                              cost.engineer &&
                              cost
                            ? 'Done'
                            : cost.engineering &&
                              !['123', undefined].includes(cost.engineer)
                            ? 'Assigned'
                            : ''
                    }}</v-chip
                >
                <p
                    class="my-0"
                    :style="{ fontWeight: 'bold', color: colorString }"
                >
                    {{
                        cost && cost.engineeringDate
                            ? formatDate(cost.engineeringDate._seconds)
                            : ''
                    }}
                </p>
                <v-icon
                    v-if="cost && cost.engineering && !cost.engineeringDate"
                    class="ml-1"
                    color="red"
                    >mdi-flag</v-icon
                >
                <v-spacer></v-spacer>
                <v-btn
                    small
                    icon
                    color="primary"
                    class="ml-1 my-0"
                    @click="openAssignEngineer"
                    v-if="
                        (cost.readyForPricing && cost.status == 'inProgress') ||
                            cost.status != 'inProgress'
                    "
                >
                    <v-icon>
                        mdi-clipboard-account
                    </v-icon>
                </v-btn>
                <v-btn
                    small
                    icon
                    color="primary"
                    class="ml-1 my-0"
                    @click="openCost"
                >
                    <v-icon>
                        mdi-eye
                    </v-icon>
                </v-btn>
                <v-btn
                    v-if="
                        cost.preDesign &&
                            cost.preDesign.length >= 1 &&
                            !cost.preDesign[cost.preDesign.length - 1]
                                .generatedOn
                    "
                    small
                    icon
                    color="red"
                    class="ml-1 my-0"
                    @click="openUnsetEngineering"
                >
                    <v-icon>
                        mdi-cancel
                    </v-icon>
                </v-btn>
            </v-row>
            <v-row dense class="d-flex px-3 align-center mt-5">
                <v-col>
                    <p>
                        <strong class="ma-0 pa-0">Scope: </strong>
                        <span class="ma-0 pa-0" style="font-weight: normal">{{
                            cost?.reference ? cost.reference : ''
                        }}</span>
                    </p>
                    <p class="mt-n3">
                        <strong class="ma-0 pa-0">Quote: </strong>
                        <span class="ma-0 pa-0" style="font-weight: normal">{{
                            cost?.quote.name ? cost.quote.name : ''
                        }}</span>
                    </p>
                    <p class="mt-n3">
                        <strong class="ma-0 pa-0">Estimator: </strong>
                        <span class="ma-0 pa-0" style="font-weight: normal">{{
                            cost?.quote.estimatorName
                                ? cost.quote.estimatorName
                                : ''
                        }}</span>
                    </p>
                </v-col>
            </v-row>
            <v-row
                class="d-flex flex-wrap font-weight-regular mb-0 mt-n3  pt-0 px-1 "
            >
                <p
                    class="my-0"
                    color="primary"
                    style="margin-left: 8px;"
                    font-weight="bold"
                >
                    <strong>CATEGORY: </strong>
                    <span style="font-weight: normal">{{
                        cost && cost.category ? cost.category : ''
                    }}</span>
                </p>
                <v-spacer></v-spacer>
                <p
                    class="my-0 mr-2"
                    color="primary"
                    style="margin-left: 8px;"
                    font-weight="bold"
                >
                    <span style="font-weight: normal">{{
                        cost && cost.category
                            ? `${
                                  engineeringHours[cost.category]?.hours &&
                                  engineeringHours[cost.category]?.count
                                      ? (
                                            engineeringHours[cost.category]
                                                .hours /
                                            engineeringHours[cost.category]
                                                .count
                                        ).toFixed(1)
                                      : 2
                              }h`
                            : ''
                    }}</span>
                </p>
            </v-row>
        </v-col>
        <v-dialog
            :retain-focus="false"
            :fullscreen="$vuetify.breakpoint.mobile"
            v-model="openForm"
            max-width="1200px"
            persistent
        >
            <NewQuoteCostForm
                v-if="openForm"
                title="Item Details"
                :originalCost="selectedCost"
                :cost="selectedCost"
                :settings="settings"
                :quote="selectedQuote"
                @closeDialog="closeCost"
                :readOnly="readOnly"
                :currency="selectedQuote.currency"
                :exchange="selectedQuote.exchange"
                :costExchanges="selectedQuote.costExchanges"
            />
        </v-dialog>
    </v-card>
</template>

<script>
import moment from 'moment'
import NewQuoteCostForm from '@/components/Quotes/NewQuoteCostForm.vue'
import _ from 'lodash'

export default {
    name: 'CostTask',
    props: {
        cost: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        settings: {
            type: Array,
            required: true,
            default: () => {},
        },
        dateTodaySeconds: {
            type: Number,
            required: true,
            default: () => null,
        },
        engineeringHours: {
            type: Object,
            required: true,
            default: () => ({}),
        },
    },
    components: {
        NewQuoteCostForm,
    },
    data: () => ({
        loding: false,
        originalCost: {},
        selectedCost: {},
        createForm: false,
        projectId: null,
        openForm: false,
        constDueDate: {},
        selectedQuote: {},
        readOnly: true,
    }),
    computed: {
        colorString() {
            const value =
                this.formatDate2(this.dateTodaySeconds) -
                this.formatDate2(this.cost.engineeringDate._seconds)

            return value == 0 ? '#fc5e03' : value < 0 ? '' : 'red'
        },
    },
    methods: {
        openAssignEngineer() {
            this.$emit('openAssignEngineer', {
                costId: this.cost.id
                    ? this.cost.id
                    : this.cost.document_id
                    ? this.cost.document_id
                    : '',
                quoteId: this.cost.quoteId,
            })
        },
        openUnsetEngineering() {
            this.$emit('openUnsetEngineering', {
                costId: this.cost.id
                    ? this.cost.id
                    : this.cost.document_id
                    ? this.cost.document_id
                    : '',
                quoteId: this.cost.quoteId,
            })
        },
        formatDate(seconds) {
            return moment.unix(seconds).format('MMM DD')
        },
        formatDate2(seconds) {
            return moment.unix(seconds).format('YYYYMMDD')
        },
        openCost() {
            this.selectedCost = _.cloneDeep(this.cost)
            this.selectedQuote = this.selectedCost.quote
            this.openForm = true
        },
        closeCost() {
            this.openForm = false
            this.selectedCost = {}
            this.originalCost = {}
            this.selectedQuote = {}
        },
    },
}
</script>

<style></style>
